import { RouteInfo } from './vertical-menu.metadata';
import {
  EmailsListFeature,
  featureNames,
  LogsFeature,
  ProceduresFeature,
  ServicesFeature,
  UsersFeature
} from "../../../environments/features";

export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'navigation.dashboard',
    icon: 'ft-home',
    class: 'dropdown nav-item',
    isExternalLink: false,
    feature: featureNames.DASHBOARD,
    submenu: [],
    roles: []
  },
  {
    path: ServicesFeature.routerPath,
    title: ServicesFeature.title,
    icon: ServicesFeature.icon,
    class: 'dropdown nav-item',
    isExternalLink: false,
    feature: ServicesFeature.key,
    submenu: [],
    roles: [],
  },
  {
    path: LogsFeature.routerPath,
    title: LogsFeature.title,
    icon: LogsFeature.icon,
    class: 'dropdown nav-item',
    isExternalLink: false,
    feature: LogsFeature.key,
    submenu: [],
    roles: [],
  },
  {
    path: UsersFeature.routerPath,
    title: UsersFeature.title,
    icon: UsersFeature.icon,
    class: 'dropdown nav-item',
    isExternalLink: false,
    feature: UsersFeature.key,
    submenu: [],
    roles: [],
  },
  {
    path: ProceduresFeature.routerPath,
    title: ProceduresFeature.title,
    icon: ProceduresFeature.icon,
    class: 'dropdown nav-item',
    isExternalLink: false,
    feature: ProceduresFeature.key,
    submenu: [],
    roles: [],
  },
  {
    path: EmailsListFeature.routerPath,
    title: EmailsListFeature.title,
    icon: EmailsListFeature.icon,
    class: 'dropdown nav-item',
    isExternalLink: false,
    feature: EmailsListFeature.key,
    submenu: [],
    roles: [],
  },
];
