export type Feature = {
  key: string; // key - feature name, please add into featureNames first and then use as constant
  title: string; // please locale variable
  routerPath: string; // for example '/services'
  gradientClass: string; // please add new class with colors into src/assets/sass/core/colors/_palette-variables.scss to section $gradient-colors
  icon: string; // for example - 'ft-file-text',
  showOnDashboard: boolean
}

export const ALLOWED_ALL_FEATURES = 'ALL';

export const featureNames = {
  DASHBOARD: 'dashboard',
  WEB_API: 'web_api',
  LOGS: 'logs',
  EMAILS_LIST: 'email_list',
  PROCEDURES: 'procedures',
  PROCEDURES_EDIT: 'procedures_edit',
  USERS: 'users',
};

export const LogsFeature: Feature = {
  key: featureNames.LOGS,
  title: 'navigation.logs',
  routerPath: '/service-logs',
  gradientClass: 'gradient-logs',
  icon: 'ft-database',
  showOnDashboard: true,
};

export const EmailsListFeature: Feature = {
  key: featureNames.EMAILS_LIST,
  title: 'navigation.emailsList',
  routerPath: '/emails-list',
  gradientClass: 'gradient-emails-list',
  icon: 'ft-mail',
  showOnDashboard: true,
};

export const UsersFeature: Feature = {
  key: featureNames.USERS,
  title: 'navigation.users',
  routerPath: '/users',
  gradientClass: 'gradient-users',
  icon: 'ft-users',
  showOnDashboard: true,
};

export const ServicesFeature: Feature = {
  key: featureNames.WEB_API,
  title: 'navigation.services',
  routerPath: '/services',
  gradientClass: 'gradient-service',
  icon: 'ft-file-text',
  showOnDashboard: true,
};

export const ProceduresFeature: Feature = {
  key: featureNames.PROCEDURES,
  title: 'navigation.procedures',
  routerPath: '/procedures',
  gradientClass: 'gradient-procedures',
  icon: 'ft-layers',
  showOnDashboard: true,
};

export const ProceduresEditFeature: Feature = {
  key: featureNames.PROCEDURES_EDIT,
  title: 'navigation.proceduresEdit',
  routerPath: '/procedures_edit',
  gradientClass: '',
  icon: '',
  showOnDashboard: false,
};

export const featureList: Feature[] = [
  ServicesFeature,
  UsersFeature,
  LogsFeature,
  ProceduresFeature,
  ProceduresEditFeature,
  EmailsListFeature,
];
